<!--
 * @Author: master
 * @Date: 2021-11-05 16:35:25
 * @LastEditTime: 2021-11-05 16:42:12
 * @Description: 匹配到空路由地址
-->
<template>
  <van-empty image="error" description="地址匹配错误">
    <van-button type="danger" class="bottom-button" @click="backLogin">返回登录</van-button>
  </van-empty>
</template>
<script>
export default {
  methods: {
    backLogin() {
      this.$router.replace('/');
    }
  }
};
</script>
